<template>
	<div class="sandbox">
		<v-container>
			<h1 class="font-weight-light">DB Crawler</h1>
			<v-row>
				<v-col cols="12" lg="6">
					<v-row>
						<v-col>
							<v-text-field v-model="collection" label="Référence"></v-text-field>
						</v-col>
						<v-col cols="auto" class="mt-3">
							<v-btn @click="getData()" color="primary" class="neon mr-3" icon>
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
							<v-btn @click="goBack()" color="warning" class="neon" icon>
								<v-icon>mdi-arrow-left</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-col>

				<v-divider vertical></v-divider>

				<v-col cols="12" lg="6">
					<v-row>
						<v-col>
							<v-text-field v-model="newFilter" label="Nouveau filtre" hint="Format: champ, opérateur, valeur"></v-text-field>
						</v-col>
						<v-col cols="auto" class="mt-3">
							<v-btn @click="transformForFilter(newFilter)" color="primary" class="neon" icon :disabled="newFilter.length < 3">
								<v-icon>mdi-filter-plus</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<v-row v-if="clauses.length > 0">
				<v-col cols="12">
					<h3 class="font-weight-light">Filtres appliqués</h3>
					<v-row>
						<v-col>
							<v-chip v-for="item in clauses" :key="item[0]" close close-icon="mdi-close" @click:close="transformForFilter(item, true)">
								{{ item }}
							</v-chip>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<v-divider class="my-6"></v-divider>
			
			<template v-if="dataSet.length < dataSize">
				<v-skeleton-loader v-for="item in dataSize" :key="item" bind="attrs" type="list-item-three-line"></v-skeleton-loader>
			</template>
			<p v-else v-for="data in dataSet" :key="data.id">
				<v-row>
					<v-col>
						{{ data }}
					</v-col>
					<v-col cols="auto">
						<v-col cols="12" v-if="lastItem != data.id">
							<v-btn @click="collection += `/${data.id}`, getData()" color="primary" class="neon">Aller dans ce document</v-btn>
						</v-col>
						<v-col cols="12" v-for="item in subcollections[subcoll]" :key="item">
							<v-btn @click="collection += `/${item}`, getData()" color="primary" class="neon">
								<v-icon>mdi-arrow-right-bottom</v-icon>
								{{ item }}
							</v-btn>
						</v-col>
					</v-col>
				</v-row>
			</p>
		</v-container>
	</div>
</template>

<script>
	export default {
		name: "DB_Crawler",
		data() {
			return {
				dataSet: [],

				dataLoaded: false,
				dataSize: 1,

				clauses: [],
				activeFilters: [],

				newFilter: '',
				collection: 'companies/9gTWluGVyXZSfpG3NOO9/customers',

				subcollections: {
					'companies': [
						'blogs',
						'companies',
						'customers',
						'modules',
						'products',
						'projects',
						'quotes',
						'tasks',
						'teams'
					],
					'customers': [
						'remarks'
					],
					'modules': [],
					'products': [],
					'ranks': [],
					'users': [
						'qrcodes',
						'ranks'
					],
					'blogs': [
						'articles',
						'integrations'
					],
					'quotes': [
						'products'
					],
					'teams': [
						'members'
					]
				}
			}
		},
		computed: {
			lastItem: function() {
				const collSplit = this.collection.split("/");
				return this.collection.split("/")[collSplit.length - 1];
			},
			subcoll: function() {
				const collSplit = this.collection.split("/");
				return this.collection.split("/")[collSplit.length - 2];
			}
		},
		methods: {
			goBack() {
				const collSplit = this.collection.split("/");

				this.collection = this.collection.replace(`/${collSplit[collSplit.length - 1]}`, "");

				this.getData();
			},
			transformForFilter(value, clauses=false) {
				let str;

				if(clauses){
					str = String(`${value[0]}, ${value[1]}, ${value[2]}`);
					str = str.split(', ');
				}
				else str = value.split(', ');

				function purificateValue(str) {
					if(!isNaN(str)) str = Number(str);
					else if(str === "true" || str === "false") str = JSON.parse(str);

					return str;
				}

				str[2] = purificateValue(str[2]);

				return this.statusFilter(str[0], str[1], str[2]);
			},
			statusFilter(field, query, value) {
				const filt = `${field}${query}${value}`;
				const index = this.activeFilters.indexOf(filt);

				if(index > -1) {
					this.activeFilters.splice(index, 1);
					this.clauses.splice(index, 1);
				}
				else {
					this.activeFilters.push(filt);
					this.clauses.push([field, query, value]);
				}

				this.getData();
			},
			async getData() {
				this.dataLoaded = false;

				this.dataSet = [];

				await this.$functions.queryDatabase(this.collection, this.clauses).then((res) => {

					if(res.size) {
						res.forEach((doc) => {
							this.dataSize = res.size;

							setTimeout(() => {
								this.dataSet.push({
									...doc.data(),
									id: doc.id
								});
							}, 500);
						});
					}
					else {
						if(!res) this.dataSize = 0;

						this.dataSize = 1;
						this.dataSet.push(res);
					}

				});

				this.dataLoaded = true;
			}
		},
		created() {
			this.getData();
		}
	}

</script>

<style>

</style>
